<template>
  <div>
    <span>{{ createdAt }}</span>
    <hcc-button-icon
      v-if="isOwner"
      size="xl"
      round
      color="primary"
      v-tooltip="edit"
      @click="openEdit('show-modal')"
      class="right-panel__icon"
      ><pencil-icon />
    </hcc-button-icon>
    <hcc-button-icon
      size="xl"
      round
      color="primary"
      v-tooltip="blocked"
      @click="openEdit('blocked-client')"
      class="right-panel__icon"
      ><account-icon />
    </hcc-button-icon>
  </div>
</template>

<script>
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';

export default {
  components: {
    HccButtonIcon,
  },
  props: {
    createdAt: {
      type: String,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    edit() {
      return this.$t('campaigns.cards.edit');
    },
    blocked() {
      return this.$t('campaigns.cards.blocked-client');
    },
  },
  methods: {
    openEdit(action) {
      this.$emit('edit', action);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
