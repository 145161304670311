<template>
  <div class="card">
    <div class="loading" v-if="isLoading" />
    <div
      class="status"
      :class="{
        'status-editable': showStatusButton,
        'status-disabled': !isActive,
      }"
      v-on="showStatusButton ? { click: changeStatus } : {}"
      v-tooltip="{ content: deactivate, visible: showStatusButton }"
    ></div>
    <div class="grid-x">
      <div v-if="isBlank" class="loader-wrapper">
        <card-loader />
      </div>
      <template v-else>
        <div class="panel-container">
          <card-left-panel
            class="left-panel"
            :campaign="campaign"
            :isOwner="isOwner"
            @updated="updateCampaign"
          />
          <card-right-panel
            class="right-panel"
            :createdAt="createdAt"
            :isOwner="isOwner"
            @edit="openEdit"
          />
        </div>
        <card-buttons
          class="card__buttons cell"
          @campaign-settings="openCampaignSettings"
          @channels-settings="openChannelsSettings"
        />
      </template>
    </div>
    <hcc-confirmation />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import CardLoader from '@/components/loaders/CampaignCard.vue';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import CardLeftPanel from './CardLeftPanel.vue';
import CardRightPanel from './CardRightPanel.vue';
import CardButtons from './CardButtons.vue';

export default {
  components: {
    CardLoader,
    HccConfirmation,
    CardLeftPanel,
    CardRightPanel,
    CardButtons,
  },
  props: {
    campaign: {
      required: true,
    },
    isOwner: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    isBlank() {
      return !!this.campaign.isBlank;
    },
    isLoading() {
      return !!this.campaign.loading;
    },
    isActive() {
      return !!this.campaign.active;
    },
    showStatusButton() {
      return !this.isOwner && this.isActive;
    },
    createdAt() {
      if (this.campaign.createdAt) {
        const createdAt = this.$moment(this.campaign.createdAt);
        return createdAt.format('DD/MM/YYYY');
      }

      return 'N/A';
    },
    deactivateCampaign() {
      return this.$t('campaigns.cards.deactivate-campaign');
    },
    deactivate() {
      return this.$t('campaigns.cards.deactivate');
    },
  },
  methods: {
    ...mapActions({
      selectCampaign: 'setting/selectCampaign',
    }),
    ...mapMutations({
      setCurrentCampaign: 'setting/setCurrentCampaign',
    }),
    changeStatus() {
      const data = {
        id: this.campaign.id,
        active: false,
      };

      this.$modal.show('confirmation', {
        title: this.deactivateCampaign,
        description: this.$t('campaigns.cards.activate-confirmation'),
        variant: 'default',
        confirm: () => this.$emit('updated', data),
      });
    },
    updateCampaign(campaign) {
      this.$emit('updated', campaign);
    },
    openEdit(action) {
      if (action === 'blocked-client') {
        this.selectCampaign(this.campaign.id);
        this.$router.push(`/campaigns/${this.campaign.id}/blocked-clients`);
      } else if (action === 'show-modal') {
        this.$emit('edit');
      }
    },
    openCampaignSettings() {
      localStorage.currentCampaign = JSON.stringify(this.campaign);
      this.$emit('campaign-settings', this.campaign.id);
    },
    openChannelsSettings() {
      localStorage.currentCampaign = JSON.stringify(this.campaign);
      this.$emit('channels-settings', this.campaign.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-card.scss";
</style>
