<template>
  <hcc-modal
    name="campaign-modal"
    :title="title"
    @confirm="handleConfirm"
    @before-open="beforeOpen"
    v-on="!newCampaign ? { opened: setData } : {}"
    @closed="cleanData"
    :confirmDisableButton="hasError"
  >
    <div class="edit-campaign-form">
      <hcc-input
        class="edit-campaign-form__input"
        :label="name"
        v-model.trim="inputName"
        autocomplete="off"
        :requiredInput="newCampaign"
        :error="hasError"
        :errorMessage="errorMessage"
        @keyup.enter="goTo"
      />
      <div v-if="isOwner">
        <hcc-select
          ref="select"
          customClass="select"
          :options="dueDateOptions"
          :label="dueDate"
          :placeholder="campaignDueDate"
          v-model="date"
          track-by="value"
          option-label="value"
        />
      </div>
      <div class="edit-campaign-form__group" v-if="isOwner">
        <div class="edit-campaign-form__toggle">
          {{ activeTitle }}
          <hcc-toggle-switch
            size="sm"
            :value="isActive"
            :name="`${campaignName}-active`"
            @change="toggleActive"
          />
        </div>
        <div class="edit-campaign-form__toggle">
          {{ billableTitle }}
          <hcc-toggle-switch
            size="sm"
            :value="isBillable"
            :name="`${campaignName}-billable`"
            @change="toggleBillable"
          />
        </div>
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '@/components/shared/HccModal/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccSelect from '@/components/shared/HccSelect/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';

export default {
  components: {
    HccModal, HccInput, HccSelect, HccToggleSwitch,
  },
  props: {
    newCampaign: {
      type: Boolean,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      campaign: null,
      inputName: '',
      active: null,
      billable: null,
      date: null,
      error: false,
      dueDateOptions: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '10',
          value: 10,
        },
        {
          label: '15',
          value: 15,
        },
        {
          label: '20',
          value: 20,
        },
        {
          label: this.$t('campaigns.cards.modal.end-of-month'),
          value: 30,
        },
      ],
    };
  },
  computed: {
    title() {
      return this.newCampaign ? this.$t('campaigns.cards.create-campaign') : this.$t('campaigns.cards.edit-campaign');
    },
    name() {
      return this.$t('campaigns.cards.modal.name');
    },
    campaignName() {
      return this.campaign ? this.campaign.name : '';
    },
    dueDate() {
      return this.$t('campaigns.cards.modal.due-date');
    },
    campaignDueDate() {
      return this.campaign ? this.campaign.dueDate.toString() : '';
    },
    activeTitle() {
      return this.$t('campaigns.cards.active');
    },
    isActive() {
      return this.campaign ? this.campaign.active : false;
    },
    billableTitle() {
      return this.$t('campaigns.cards.modal.billable');
    },
    isBillable() {
      return this.campaign ? this.campaign.billable : false;
    },
    errorMessage() {
      return this.$t('campaigns.cards.modal.error-message');
    },
    hasError() {
      return (this.active !== null || this.billable !== null || this.date !== null)
        && this.inputName.length === 0;
    },
    isOwner() {
      return this.role === 'owner';
    },
  },
  methods: {
    beforeOpen(event) {
      this.campaign = event.params;
    },
    setData() {
      this.inputName = this.campaign.name;
    },
    toggleActive(value) {
      this.active = value;
    },
    toggleBillable(value) {
      this.billable = value;
    },
    handleConfirm() {
      let data;

      if (!this.newCampaign) {
        data = {
          id: this.campaign.id,
          name: this.inputName || this.campaign.name,
          dueDate: this.date ? this.date.value : this.campaign.dueDate,
          active: this.active !== null ? this.active : this.campaign.active,
          billable: this.billable !== null ? this.billable : this.campaign.billable,
        };
        this.$emit('edited', data);
      } else {
        if (this.isOwner) {
          data = {
            name: this.inputName,
            dueDate: this.date.value,
            active: this.active,
            billable: this.billable,
          };
        } else {
          data = {
            name: this.inputName,
            dueDate: 15,
            active: true,
            billable: false,
          };
        }
        this.$emit('saved', data);
      }
    },
    cleanData() {
      this.campaign = null;
      this.inputName = '';
      this.date = null;
      this.active = null;
      this.billable = null;
    },
    goTo() {
      this.$refs.select.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
