<template>
  <div class="grid-x">
    <hcc-button
      v-if="isValidUser"
      class="card-buttons__settings-button"
      @click="openChannelsSettings"
      variant="transparent"
      color="primary"
    >
      {{ channelSettings }}
    </hcc-button>
    <hcc-button
      class="card-buttons__settings-button"
      variant="transparent"
      color="secondary"
      @click="openCampaignSettings"
    >
      {{ campaignSettings }}
    </hcc-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  computed: {
    ...mapGetters({
      userRole: 'shared/getUserRole',
    }),
    campaignSettings() {
      return this.$t('campaigns.cards.campaign-settings');
    },
    channelSettings() {
      return this.$t('campaigns.cards.channels-settings');
    },
    isValidUser() {
      return ['owner', 'super'].includes(this.userRole);
    },
  },
  methods: {
    openCampaignSettings() {
      this.$emit('campaign-settings');
    },
    openChannelsSettings() {
      this.$emit('channels-settings');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/_campaign-card.scss";
</style>
