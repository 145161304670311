<template>
  <div>
    <section class="main-content">
      <div v-show="isOwner" class="grid-x align-right margin-bottom--20">
        <div class="cell shrink">
          <button-filter
            v-bind:display="enabledFilters"
            v-on:change="onFiltersChanged"
          />
        </div>
      </div>
      <filters v-show="isOwner" :display="enabledFilters" />
      <div class="grid-x grid-margin-x grid-margin-y loader-container">
        <overlay-loader :loading="isLoading" />
        <div
          v-for="campaign in campaigns"
          :key="campaign.id"
          class="cell medium-6"
        >
          <campaign-card
            @edit="showModal(campaign, false)"
            @updated="updateCampaign"
            @campaign-settings="openCampaignSettings"
            @channels-settings="openChannelsSettings"
            :is-owner="isOwner"
            :campaign="campaign"
          />
        </div>

        <div class="cell medium-6" v-if="canCreate">
          <campaign-card-create
            :is-owner="isOwner"
            @create="showModal(null, true)"
            @saved="createCampaign"
          />
        </div>
      </div>
    </section>
    <campaign-modal
      v-if="canCreate"
      @edited="updateCampaign"
      @saved="createCampaign"
      :newCampaign="newCampaign"
      :role="role"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import addCampaignMutation from '@/graphql/mutations/addCampaign.gql';
import editCampaignMutation from '@/graphql/mutations/editCampaign.gql';
import CampaignCard from '@/components/CampaignCard/index.vue';
import CampaignModal from '@/components/CampaignCard/CampaignModal.vue';
import Filters from '@/components/Filters.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    CampaignCard,
    CampaignModal,
    OverlayLoader,
    Filters,
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    CampaignCardCreate: () => import('@/components/CampaignCardCreate.vue'),
  },
  data() {
    return {
      filters: null,
      enabledFilters: ['company'],
      newCampaign: null,
      isLoading: false,
      selectedCompany: null,
    };
  },
  computed: {
    ...mapGetters({
      isOwner: 'shared/isOwner',
      campaigns: 'setting/getCampaigns',
      role: 'shared/getUserRole',
    }),
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany.id;
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : 0;
    },
    canCreate() {
      return this.role === 'owner' || this.role === 'super';
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue !== null && (oldValue === null || newValue.companyId !== oldValue.companyId)) {
        this.loadCampaigns();
        this.isLoading = true;
      }
    },
  },
  mounted() {
    if (this.filters !== null || !this.isOwner) {
      this.loadCampaigns();
    }
  },
  methods: {
    ...mapMutations({
      clearCurrentCampaign: 'setting/clearCurrentCampaign',
    }),
    ...mapActions({
      fetchCompanies: 'shared/fetchCompanies',
      fetchCampaigns: 'setting/fetchCampaigns',
      selectCampaign: 'setting/selectCampaign',
      updateCampaigns: 'setting/updateCampaign',
      addCampaign: 'setting/addCampaign',
      clearDatabank: 'databank/clearDatabank',
    }),
    onFiltersChanged(filters) {
      if (filters.company) {
        this.selectedCompany = filters.company.name;
      }

      this.filters = filters;
    },
    async loadCampaigns() {
      const result = await this.fetchCampaigns(this.currentCompanyId);
      if (result === 'error') this.$toasted.global.error(this.$t('alerts.unexpectedError'));
      this.isLoading = false;
    },
    async createCampaign(data) {
      const result = await this.$apollo.mutate({
        mutation: addCampaignMutation,
        variables: {
          ...data,
          company: this.currentCompanyId,
        },
      });

      this.addCampaign(result.data.addCampaign);
      this.fetchCompanies();
    },
    async updateCampaign(data) {
      try {
        this.updateLoadingCard(data.id, true);
        const result = await this.$apollo.mutate({
          mutation: editCampaignMutation,
          variables: data,
        });

        this.$toasted.global.success(this.$t('campaigns.updated'));
        this.updateCampaigns(result.data.editCampaign);
        this.updateLoadingCard(data.id, false);
        this.fetchCompanies();
      } catch (error) {
        this.$toasted.global.error(this.$t('common.error-saving'));
        errorHandler.logErrors(error);
      }
    },
    updateLoadingCard(id, status) {
      const index = this.getCampaignIndex(id);

      if (index > -1) {
        this.campaigns[index].loading = status;
      }
    },
    getCampaignIndex(id) {
      return this.campaigns.findIndex(c => c.id === id);
    },
    showModal(campaign, isNew) {
      this.newCampaign = isNew;
      this.$modal.show('campaign-modal', campaign);
    },
    openCampaignSettings(id) {
      this.clearDatabank();
      this.selectCampaign(id);
      this.$router.push(`/campaigns/${id}/settings`);
    },
    openChannelsSettings(id) {
      this.selectCampaign(id);
      this.$router.push(`/campaigns/${id}/channels-settings`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~vue-loading-overlay/dist/vue-loading.css";
@import "~styles/views/_campaigns.scss";
</style>
